import { TelemetryEvent } from "@ds160/library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertsContext, AlertType, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

export interface IPostUserRequestResponseBody {
    readonly token: string;
}

interface IRequestPayload {
    readonly email: string;
    readonly password: string;
    readonly subscriptionOrderId?: string;
}

const getMutationQuery = (token: string | null, body: IRequestPayload) => {
    const postRequest = async (): Promise<IPostUserRequestResponseBody> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.post(`${Constants.ApiRoot}/user`, body, config);

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const usePostUser = (form: IRequestPayload, onSuccess: (response: IPostUserRequestResponseBody) => void) => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(authToken, form), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.SignupStart, null);
        },
        onSuccess: (data) => {
            telemetry.Log(TelemetryEvent.SignupSuccess, null);
            queryClient.invalidateQueries([QueryCacheKey.User]);
            onSuccess(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.SignupError, submitError);
        },
    });

    return { mutation };
};
